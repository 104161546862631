import React from 'react';
import { createUseStyles } from 'react-jss';
import { spacing, breakpoint, pxToRem } from 'src/theme';
import { assetLink } from 'src/utils';
import Typography from 'src/components/core/Typography';
import SectionContainer from '../shared/SectionContainer';

const useStyles = createUseStyles({
  container: {
    background: '#F7F7F7',
  },
  grid: {
    display: 'grid',
    justifyContent: 'center',
    columnGap: spacing(10),
    rowGap: spacing(10),
    marginTop: spacing(15),
    gridTemplateColumns: `repeat(3, minmax(0, ${pxToRem(420)}))`,
    [breakpoint.down('sm')]: {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      marginTop: spacing(10),
    },
  },
  icon: {
    height: pxToRem(60),
  },
});

const content: {
  iconSrc: string,
  label: string,
  details: string,
}[] = [
  {
    iconSrc: assetLink('landing-ui/icons/NoMoneyIcon.svg'),
    label: 'It\'s free to join',
    details: 'We want you to make money through your business with us. There\'s no fee to become an affiliate.',
  },
  {
    iconSrc: assetLink('landing-ui/icons/PersonCheckIcon.svg'),
    label: 'Endless earnings',
    details: '$300 per referral adds up There\'s no limit to the amount of businesses you can send our way.',
  },
  {
    iconSrc: assetLink('landing-ui/icons/ExclusiveIcon.svg'),
    label: 'Exclusive access',
    details: 'We offer affiliates exclusive looks at new features and offer sneak peeks of what\'s to come.',
  },
];

const WhyBecomeAffiliate: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container}>
      <div className="ml-auto mr-auto" style={{ maxWidth: pxToRem(500) }}>
        <Typography color="blue" variant="h2" underline centered style={{ }}>
          Why should you become an affiliate?
        </Typography>
      </div>
      <div className={classes.grid}>
        {content.map(({ iconSrc, label, details }) => (
          <div className="flex flex-col items-center" key={label}>
            <img src={iconSrc} alt={`${label} Icon`} className={classes.icon} />
            <Typography variant="h3" color="blue" centered style={{ marginTop: spacing(6) }}>
              {label}
            </Typography>
            <Typography variant="p1" color="blue" centered style={{ marginTop: spacing(2) }}>
              {details}
            </Typography>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};

export default WhyBecomeAffiliate;
