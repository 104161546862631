import React from 'react';
import { createUseStyles } from 'react-jss';
import { spacing, breakpoint, pxToRem } from 'src/theme';
import { assetLink } from 'src/utils';
import Typography from 'src/components/core/Typography';
import SectionContainer from '../shared/SectionContainer';

const useStyles = createUseStyles({
  container: {
    background: '#F7F7F7',
  },
  grid: {
    display: 'grid',
    justifyContent: 'center',
    columnGap: spacing(10),
    rowGap: spacing(10),
    marginTop: spacing(15),
    gridTemplateColumns: `repeat(3, minmax(0, ${pxToRem(420)}))`,
    [breakpoint.down('sm')]: {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      marginTop: spacing(10),
    },
  },
  icon: {
    height: pxToRem(60),
  },
});

const content: {
  iconSrc: string,
  label: string,
  details: string,
}[] = [
  {
    iconSrc: assetLink('landing-ui/icons/JoinIcon.svg'),
    label: 'Join',
    details: 'Free to get started, with access to promotional materials, a custom dashboard, and more.',
  },
  {
    iconSrc: assetLink('landing-ui/icons/MarketingIconOrange.svg'),
    label: 'Recommend',
    details: 'Share Bizwise with your audience, and businesses that would benefit.',
  },
  {
    iconSrc: assetLink('landing-ui/icons/PersonMoneyIcon.svg'),
    label: 'Earn',
    details: 'Earn right from the moment your traffic converts.',
  },
];

const HowItWorks: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container}>
      <div className="ml-auto mr-auto" style={{ maxWidth: pxToRem(500) }}>
        <Typography color="blue" variant="h2" underline centered>
          How It Works
        </Typography>
      </div>
      <div className={classes.grid}>
        {content.map(({ iconSrc, label, details }, i) => (
          <div className="flex flex-col items-center" key={label}>
            <div className="mb-6 desktop:mb-8 rounded-[50%] bg-blue flex justify-center items-center w-[50px] h-[50px]">
              <Typography variant="h3" color="white">
                {i + 1}
              </Typography>
            </div>
            <img src={iconSrc} alt={`${label} Icon`} className={classes.icon} />
            <Typography variant="h3" color="blue" centered style={{ marginTop: spacing(6) }}>
              {label}
            </Typography>
            <Typography variant="p1" color="blue" centered style={{ marginTop: spacing(2) }}>
              {details}
            </Typography>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};

export default HowItWorks;
