import React from 'react';
import { createUseStyles } from 'react-jss';
import { pxToRem } from 'src/theme';
import Typography from 'src/components/core/Typography';
import clsx from 'clsx';
import SectionContainer from '../shared/SectionContainer';
import { SectionImages } from '../shared/StaticImages';

const useStyles = createUseStyles({
  container: {
    background: '#F7F7F7',
  },
  img: {
    borderRadius: 10,
    '& > *': {
      borderRadius: 10,
    },
  },
});

const content: {
  img: React.ReactElement,
  label: string,
  details: string,
}[] = [
  {
    img: SectionImages[17],
    label: 'Maximum Earnings',
    details: 'Paid for every new customer that signs up. No limit on earning.',
  },
  {
    img: SectionImages[18],
    label: 'Awesome Tools',
    details: 'Easy to use tools to help you manage, track, and grow your conversations.',
  },
  {
    img: SectionImages[19],
    label: 'Support From Bizwise',
    details: 'Access professional support, tutorials, and a dedicated affiliate manager.',
  },
  {
    img: SectionImages[20],
    label: 'Marketing Resources',
    details: 'A portfolio of high performing resources to help you promote.',
  },
];

const AffiliateBenefits: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container}>
      <Typography color="blue" variant="h2" centered className="mb-8">
        Our Affiliate Benefits
      </Typography>
      <div className="mx-auto grid grid-cols-1 desktop:grid-cols-2 gap-x-8 gap-y-8 desktop:gap-y-16" style={{ maxWidth: pxToRem(1000) }}>
        {content.map(({ img, label, details }) => (
          <div>
            {React.cloneElement(img, {
              className: clsx(
                classes.img,
              ),
              style: {
                aspectRatio: '3/2',
              },
            })}
            <Typography variant="h3" color="blue" centered className="mt-8">
              {label}
            </Typography>
            <Typography variant="p3" color="textSecondary" centered className="mt-3">
              {details}
            </Typography>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};

export default AffiliateBenefits;
