import React from 'react';
import { createUseStyles } from 'react-jss';
import { spacing, breakpoint, pxToRem } from 'src/theme';
import { assetLink } from 'src/utils';
import Typography from 'src/components/core/Typography';
import SectionContainer from '../shared/SectionContainer';

const useStyles = createUseStyles({
  container: {
    background: '#F7F7F7',
  },
  grid: {
    display: 'grid',
    justifyContent: 'center',
    columnGap: spacing(10),
    rowGap: spacing(10),
    marginTop: spacing(15),
    gridTemplateColumns: `repeat(3, minmax(0, ${pxToRem(420)}))`,
    [breakpoint.down('sm')]: {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      marginTop: spacing(10),
    },
  },
  icon: {
    height: pxToRem(60),
  },
});

const content: {
  iconSrc: string,
  details: string,
}[] = [
  {
    iconSrc: assetLink('landing-ui/icons/ReferralMoneyIcon.svg'),
    details: 'Referrals can only be bought by first-time buyers',
  },
  {
    iconSrc: assetLink('landing-ui/icons/CalendarAlarmIcon.svg'),
    details: 'Your referral must sign up within 30 days of you nominating them',
  },
  {
    iconSrc: assetLink('landing-ui/icons/HourglassMoneyIcon.svg'),
    details: 'You can gain comissions for a year',
  },
];

const AffiliateRules: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <SectionContainer
        style={{
          background: 'linear-gradient(180deg, #F7F7F7 50%, #EBF1FF 50%)',
          paddingTop: spacing(5),
          paddingBottom: spacing(5),
        }}
      >
        <div
          className="w-full mx-auto rounded-[20px] px-10 sm:px-20 py-8"
          style={{
            background: 'radial-gradient(98.72% 384.65% at 0% 3.44%, #3D78BB 0%, #13266D 92.07%), #13296B',
            maxWidth: pxToRem(600),
          }}
        >
          <Typography variant="h3" color="white" centered>
            We could all use an extra income, so why not get one through us?
          </Typography>
          <Typography variant="p3" color="white" centered className="mt-2">
            Through our affiliate program, you will receive
          </Typography>
          <Typography variant="h1" color="blue-secondary" centered className="my-2">
            $300
          </Typography>
          <Typography variant="p3" color="white" centered>
            per every new small business sent our way.
          </Typography>
        </div>
      </SectionContainer>
      <SectionContainer
        className={classes.container}
        background="light-blue"
      >
        <div className="ml-auto mr-auto" style={{ maxWidth: pxToRem(500) }}>
          <Typography color="blue" variant="h2" underline centered>
            The Fine Print
          </Typography>
        </div>
        <div className={classes.grid}>
          {content.map(({ iconSrc, details }, i) => (
            <div className="flex flex-col items-center" key={iconSrc}>
              <div className="mb-6 desktop:mb-8 rounded-[50%] bg-blue flex justify-center items-center w-[50px] h-[50px]">
                <Typography variant="h3" color="white">
                  {i + 1}
                </Typography>
              </div>
              <img src={iconSrc} alt="icon" className={classes.icon} />
              <Typography variant="p1" color="blue" centered style={{ marginTop: spacing(2) }}>
                {details}
              </Typography>
            </div>
          ))}
        </div>
      </SectionContainer>
    </>
  );
};

export default AffiliateRules;
