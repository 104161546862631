import React from 'react';
import { createUseStyles } from 'react-jss';
import { breakpoint, spacing } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import Button from 'src/components/core/Button';
import { REFERRAL_APPLICATION_LINK } from 'src/constants';

const useStyles = createUseStyles({
  container: {
    background: 'linear-gradient(109deg, #13266D 0.49%, #00B6B6 130.79%), #F7F7F7',
    [breakpoint.down('sm')]: {
      background: 'linear-gradient(185.87deg, #13266D 25.84%, #00B6B6 117.58%), #F7F7F7',
    },
    [breakpoint.up('md')]: {
      padding: `${spacing(20)} 4vw`,
    },
    [breakpoint.down('md')]: {
      padding: '12vw 4vw',
    },
  },
});

const AffiliateApply: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container}>
      <Typography variant="h2" color="white" centered>
        Ready to become
        <br />
        a Bizwise Affiliate?
      </Typography>
      <Button
        variant="filled"
        color="teal"
        className="w-full sm:w-auto block mt-6 mx-auto block"
        href={REFERRAL_APPLICATION_LINK}
        isExternal
      >
        Apply Now
      </Button>
    </SectionContainer>
  );
};

export default AffiliateApply;
