import React from 'react';
import { createUseStyles } from 'react-jss';
import { pxToRem, breakpoint, spacing } from 'src/theme';
import SlantedSection from 'src/sections/shared/SlantedSection';
import Typography from 'src/components/core/Typography';
import Button from 'src/components/core/Button';
import clsx from 'clsx';
import { REFERRAL_APPLICATION_LINK } from 'src/constants';
import { SectionImages } from '../shared/StaticImages';

const useStyles = createUseStyles({
  container: {
    height: pxToRem(650),
    minHeight: pxToRem(650),
    [breakpoint.down('md')]: {
      height: 'auto',
      minHeight: 'auto',
    },
  },
  content: {
    paddingTop: spacing(20),
    paddingBottom: spacing(20),
    paddingLeft: spacing(10),
    marginLeft: 'auto',
    marginRight: 'auto',
    [breakpoint.down('md')]: {
      marginLeft: 0,
      marginRight: 0,
      paddingTop: spacing(2),
      paddingBottom: spacing(15),
    },
    [breakpoint.down('sm')]: {
      paddingLeft: spacing(6),
      paddingRight: spacing(6),
      paddingTop: spacing(2),
      paddingBottom: spacing(15),
    },
  },
  contentBackground: {
    background: 'linear-gradient(112.2deg, #13266D 38.33%, #5FBCFF 132.24%)',
    [breakpoint.down('sm')]: {
      background: 'linear-gradient(100.72deg, #13266D -14.19%, #5FBCFF 282.47%), #13296B',
    },
  },
  text: {
    width: '100%',
    maxWidth: pxToRem(530),
  },
});

const Hero: React.FC = () => {
  const classes = useStyles();
  return (
    <SlantedSection
      img={SectionImages[16]}
      contentWidth={0.65}
      className={classes.container}
      contentClassName={classes.contentBackground}
      showNavbarGradient
    >
      <div className={classes.content}>
        <Typography
          variant="h3"
          color="white"
          className={clsx(classes.text, 'mb-4')}
          style={{ letterSpacing: '0.04rem' }}
        >
          Bizwise Affiliate Program
        </Typography>
        <Typography
          variant="h1"
          color="white"
          className={classes.text}
          style={{
            letterSpacing: '0.03rem',
            lineHeight: '130%',
          }}
        >
          Money To Grow Your Business?
        </Typography>
        <Button
          variant="filled"
          color="teal"
          className="w-full sm:w-auto mt-6"
          href={REFERRAL_APPLICATION_LINK}
          isExternal
        >
          Apply Now
        </Button>
      </div>
    </SlantedSection>
  );
};

export default Hero;
