import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import ApiWrapper from 'src/components/ApiWrapper';
import HeroSection from 'src/sections/Affiliate/Hero';
import WhyBecomeAffiliate from 'src/sections/Affiliate/WhyBecomeAffiliate';
import HowItWorks from 'src/sections/Affiliate/HowItWorks';
import AffiliateBenefits from 'src/sections/Affiliate/Benefits';
import AffiliateApply from 'src/sections/Affiliate/Apply';
import AffiliateRules from 'src/sections/Affiliate/Rules';

const AffiliatePage: React.FC = () => (
  <ApiWrapper apiServices={['home-api']}>
    <Layout>
      <Seo title="Affiliates - Bizwise" />
      <HeroSection />
      <WhyBecomeAffiliate />
      <AffiliateRules />
      <HowItWorks />
      <AffiliateBenefits />
      <AffiliateApply />
    </Layout>
  </ApiWrapper>
);

export default AffiliatePage;
